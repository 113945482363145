<template>
  <div class="content">
    <div class="banner">
        <div class="hint">
            <div class="logo"><img src="@/assets/bridge/img/logo.jpg"></div>
            <div>欢迎使用桥梁健康监控系统</div>
        </div>
    </div>
    <div class="info">
      <div class="inner">
          <van-cell title="产品介绍" is-link @click="hint"/>
          <van-cell title="使用说明" is-link @click="hint"/>
          <!-- <van-cell title="切换项目" is-link to='/bridge'/> -->
          <van-cell title="版本号" value="v1.0.0" @click="version"/>
      </div>
    </div>
    <tabbar />
  </div>
</template>
<script>
import tabbar from '@/components/bridge/LABtabbar.vue'
export default {
  components: {
    tabbar
  },
  data() {
    return {
    }
  },
  methods:{
    hint(){
      this.$toast('敬请期待！');
    },
    version(){
      this.$toast('当前是最新版本');
    }
  }
}
</script>
<style lang="scss" scoped>
.banner{
  margin-bottom: 10px;
  height: 12rem;
  background:linear-gradient(#1769ce 0%, #0BD1C0 85%);
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  text-align: center;
  .hint{
    color: white;font-size: 1rem;padding: 15% 0;
    .logo img{width:65px;height: 65px;border-radius: 50%;margin: 2%;}
  }
}
.info{
  padding:0 4%;text-align: left;
  .inner{
    padding:0 2%;background-color: white;border-radius:10px;
    .van-cell{padding: 16px}
    .van-cell__title{font-weight: bold;color: #363636;}
    }
}


</style>